* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  /* list-style: none; */
}
a {
  color: inherit;
}

body {
  background: #eee;
  font-family: "Open Sans", sans-serif;
}
